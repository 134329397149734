.animation_pulse_1 {
  fill: red;
  stroke: red;
  stroke-opacity: 0.4;

  &.animation {
    stroke-opacity: 1;
    animation: pulse1 2.5s infinite;
  }
}

@keyframes pulse1 {
  0% {
    stroke-width: 2;
    stroke: rgba(229, 62, 62, 1);
  }

  100% {
    stroke-width: 20;
    stroke: rgba(229, 62, 62, 0);
  }
}

.animation_pulse_0 {
  fill: blue;
  stroke: blue;
  stroke-opacity: 0.4;

  &.animation {
    stroke-opacity: 1;
    animation: pulse0 2.5s infinite;
  }
}

@keyframes pulse0 {
  0% {
    stroke-width: 2;
    stroke: rgb(62, 73, 229);
  }

  100% {
    stroke-width: 20;
    stroke: rgba(229, 62, 62, 0);
  }
}

.indicator {
  animation: indicatorAnimation var(--indicator-speed-timing) linear;
}

@keyframes indicatorAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

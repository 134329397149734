.button {
  color: black;

  &:active {
    transform: translate(0);
  }

  &:disabled {
    background: transparent;
    color: black;
  }
}

.content::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 95%;
  bottom: 0;
  content: "";

  background: linear-gradient(rgba(0, 0, 0, 0) 60%, black 300%);
  pointer-events: none;
}

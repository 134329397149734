.summaryWrapper {
  :global(ion-list) {
    background: white;
  }
}

.summary {
  :global(ion-item::part(native)) {
    padding: 0;
    background: white;
  }
}

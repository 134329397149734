.gsiMaterialButton {
  width: 100%;
  background-color: #131314;
  background-image: none;
  border-radius: 10px;
  box-sizing: border-box;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  height: 48px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 375px;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  min-width: min-content;

  & .gsiMaterialButtonIcon {
    height: 20px;
    margin-right: 5px;
    min-width: 20px;
    width: 20px;
  }
  & .gsiMaterialButtonContentWrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  & .gsiMaterialButtonContents {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    font-size: clamp(14px, calc(1vw + 1vh), 16px);
    font-family: "Roboto", arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  & .gsi-material-button-state {
    -webkit-transition: opacity 0.218s;
    transition: opacity 0.218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.gsiMaterialButton:disabled {
  cursor: default;
  background-color: #13131461;
  border-color: #8e918f1f;
}

.gsiMaterialButton:disabled .gsiMaterialButtonState {
  background-color: #e3e3e31f;
}

.gsiMaterialButton:disabled .gsiMaterialButtonContents {
  opacity: 38%;
}

.gsiMaterialButton:disabled .gsiMaterialButtonIcon {
  opacity: 38%;
}

.gsiMaterialButton:not(:disabled):active .gsiMaterialButtonState,
.gsiMaterialButton:not(:disabled):focus .gsiMaterialButtonState {
  background-color: white;
  opacity: 12%;
}

.gsiMaterialButton:not(:disabled):hover {
  -webkit-box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsiMaterialButton:not(:disabled):hover .gsiMaterialButtonState {
  background-color: white;
  opacity: 8%;
}

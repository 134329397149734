.highlight {
  position: absolute;
  bottom: 0;
  left: 0px;
  height: 2px;
  width: 0px;
  background-color: #3399ff;
  z-index: 50;
  transition: all ease 300ms;
}
